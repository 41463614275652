/* UserTable.css */
.user-table-container {
    max-width: 100%;
    box-sizing: border-box;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .add-user-btn {
    padding: 10px 20px;
  }

  .search-box {
    max-width: 300px;
    width: 100%;
  }

  .items-per-page {
    max-width: 150px;
  }

  .table-responsive {
    overflow-x: auto;
    margin-bottom: 20px;
  }

  .user-table {
    min-width: 600px;
  }

  .user-table th,
  .user-table td {
    text-align: left;
    padding: 10px;
  }

  .user-table td {
    border-bottom: 1px solid #f1f1f1;
  }

  .user-table tr:hover {
    background-color: #f9f9f9;
  }

  .action-btn {
    padding: 5px 10px;
    margin-right: 5px;
  }

  .pagination-container {
    margin-bottom: 20px;
    text-align: center;
  }

  .pagination-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  /* Styles for Mobile */
  @media (max-width: 768px) {
    .table-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .search-box {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;

    }
    .items-per-page {
      width: 100%;
      margin-bottom: 10px;
    }

    .user-table {
      min-width: 400px;
    }
  }
