/* Base reset and layout */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, sans-serif;
  }

  /* A5 Page Setup for Print */
  @page {
    size: A5;
    margin: 20mm;
  }

  /* Wrapper for the entire Challan */
  .challan-container {
    width: 100%;
    max-width: 148mm;  /* A5 width */
    margin: 0 auto;
    padding: 10mm;
    background-color: #fff;
  }

  /* Title */
  .challan-container h2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }

  /* Challan Details */
  .challan-details {
    margin-bottom: 15px;
  }

  .challan-details p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 5px;
  }

  /* Table Styles */
  .challan-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
  }

  .challan-table th,
  .challan-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 14px;
  }

  .challan-table th {
    background-color: #f4f4f4;
  }

  /* Summary Section */
  .challan-summary {
    margin-top: 15px;
  }

  .challan-summary p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 5px;
  }

  /* Footer */
  .challan-footer {
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    color: #777;
  }

  /* Print-specific adjustments */
  @media print {
    body {
      margin: 0;
      padding: 0;
    }

    .challan-container {
      box-shadow: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .challan-footer {
      font-size: 10px;
    }

    .challan-table th,
    .challan-table td {
      font-size: 12px;
    }

    .challan-container h2 {
      font-size: 18px;
    }

    /* Prevent page breaks inside important elements */
    .challan-container {
      page-break-inside: avoid;
    }
  }
